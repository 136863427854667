<template>
  <div/>
</template>

<script>

import {prepareSinRequest, prepareSinResponse} from '@/utils/http';
import BaseAction from '@/components/actions/BaseAction';

export default {
  name: 'OpenSchedule',
  props: {
    collection: {
      type: Object,
      required: true,
    },
  },
  mixins: [
    BaseAction,
  ],
  mounted() {
    if (!this.selected) {
      return;
    }
    this.getSchedule();
  },
  methods: {
    async getSchedule() {
      const routeID = this.selected.routesregisterId;
      const dateBegin = this.$store.getters['period/begin'];
      const dateEnd = this.$store.getters['period/end'];
      const startDtMillis = dateBegin.getTime();
      const endDtMillis = dateEnd.getTime();
      const query = `sin2:/v:9622d81a-5411-4350-a324-a21c7604b261/?filter=and(eq(field(".route"),param("${routeID}", "id")),lte(field(".verStart"),var("util.date.dateFromMillis(${endDtMillis}l)","date")),or(isnull(field(".verEnd")),gte(field(".verEnd"),var("util.date.dateFromMillis(${startDtMillis}l)","date"))),not(eq(field(".verStatus"),param("ed4762ee-ca91-4b52-89e8-1176fb0a296e","id"))))&fields=.ID,.tmpFile,.isfile,.version,.verStart&sort=-vcReleaseSchedules.verStart`;
      const rs = await prepareSinResponse(
        await prepareSinRequest(
          'core-read',
          query,
        ),
        {
          hideUnderscores: true,
        },
      );
      let msg = 'Документ не добавлен в систему для расписания № ';
      let delimiter = '';
      if (rs.length > 0) {
        for (const row of rs) {
          if (row.isfile) {
            location.href = '/rpc/?d=file&uri=fs:id:' + row.tmpfile;
          } else {
            msg += row.version + " от " + row.verstart + ".";
            delimiter = ',';
          }
        }
        if (msg !== 'Документ не добавлен в систему для расписания № ') {
          this.showMessage('warning', msg);
        }
      } else {
        this.showMessage('warning', 'На рабочий период нет действующего расписания.');
      }
    },
  },
};
</script>
